<template>
  <div class="py-3 d-flex justify-content-center"
       style="background-color: #f2f2f2;    border-bottom: #428bca 10px solid;">
    <div class="w1180 bg-white p-4 my-4">
      <NewsHeader :c_title="info.title" :c_date="info.date"/>

      <div class="x12 text-big">
        <p><img loading="lazy" class="wp-image-23622 aligncenter" src="@/assets/news/22/1.png" alt="图片" width="528" height="330"  sizes="(max-width: 528px) 100vw, 528px"></p>
        <p>美国时间2022年3月29日，美国移民局（USCIS）在官网发布了一则公告称，旨在改善移民申请的效率，并提出了三项措施。分别是：加快速度处理积案、扩大加急申请范围以及扩大部分行业工作许可范围。</p>
        <p>1，Reducing Processing Backlogs——加快速度处理积压案件</p>
        <p>移民局将计划引入一个内部的时间管控指标，叫“Cycle Time”，给不同申请确定了一个内部的审案进度指标。移民局称，随着时间表不断改善，申请人将更快地收到结果。预计将在2023财年末增加能力、改进技术并扩大人员配置，以实现这些新目标。</p>
        <p>移民局指出，其网站上公开列明的时间显示的是各种类别表格处理所需的平均时间，即从该部门收到申请直至做出决定的时间。而这次公布的内部时间表显示的是未决申请的平均等待时间，以衡量其是否在减少积压案件上取得进展。</p>
        <p>目标如下：</p>
        <p><img loading="lazy" class="wp-image-23623 aligncenter" src="@/assets/news/22/2.png" alt="图片" width="535" height="278"  sizes="(max-width: 535px) 100vw, 535px"></p>
        <p>根据上图可以看出，移民局计划将：</p>
        <p>1. Combo卡的审批，控制在3个月；</p>
        <p>2. I-485和I-526审批，控制在6个月；</p>
        <p>3.暂时还未看到I-829的计划审批时长。</p>
        <p>虽然移民局暂未公布I-829审批周期目标，但有另外一个相关消息值得我们参考和关注。在本月刚刚通过的 EB-5 改革和诚信法案中，国会要求移民局在该法案生效后一年内完成调整申请费以提高审批效率的研究，并且在研究完成后60天内制定相关申请费用。法案中提出的审理周期目标是：在 240 天内裁决基于在非目标就业区投资递交的 I-526 申请；在120天内裁决基于在目标就业区投资递交的 I-526 申请；在240 天内裁决I-829申请。截至目前，移民局尚未公布对新法实施的相关意见和指南，我们也将持续关注。</p>
        <p>2，Expanding Premium Processing——扩大加急审批的范围</p>
        <p>目前加急申请的类别仅限于I-129（工作签证申请）以及部分类别的I-140外籍工作者移民申请（包括EB-1A杰出人才移民、EB-1B杰出教授和研究人员移民、除NIW之外的EB-2技术移民等）。美国移民局计划在 2022 财年分阶段开始向 I-539（延长/调整非移民身份申请）、I-765 （工卡申请）和I-140（外籍工作者移民申请）申请人开放加急申请。作为分阶段实施的第一步，移民局计划先扩大I-140 申请人的加急申请资格范围，允许EB-1C跨国公司高管或NIW（国家利益豁免类EB-2技术移民）申请人递交加急申请。</p>
        <p>3，Improving Access to Employment Authorization Documents——拓展EAD工卡延期的适用范围。</p>
        <p>近几个月以来其已为健保行业、儿童照护等方面劳工提供了加快的工作许可申请或延期申请。未来还将进一步确保这些符合要求的劳工不会因为等待移民局决定而暂时失去工作许可。</p>
        <p>来源：https://www.uscis.gov/newsroom/news-releases/uscis-announces-new-actions-to-reduce-backlogs-expand-premium-processing-and-provide-relief-to-work</p>
      </div>

      <NewsFooter class="mt-5" :next="next" :prev="prev"/>
    </div>
  </div>

</template>

<script>
import NewsHeader from "@/components/News/NewsHeader";
import NewsFooter from "@/components/News/NewsFooter";
import news from "@/news";

export default {
  name: "news22",
  components: {NewsHeader, NewsFooter},
  data() {
    return {
      id:22,
      info: {},
      next: null,
      prev: null
    }
  }, mounted() {
    let id = this.id
    this.info = news.find(item => item.id === id)
    this.next = news.find(item => item.id === (id + 1))
    this.prev = news.find(item => item.id === (id - 1))
  }
}
</script>

<style scoped>
.aligncenter {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

a {
  color: #428bca;
  text-decoration: none;
}
</style>